<script setup lang="ts">
import { useForm } from 'vee-validate'
import { newPasswordSchema } from 'auth/validation-schema'
import KeyIcon from '@/assets/images/key.svg'

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { defineComponentBinds, handleSubmit, errors, submitCount } = useForm({
  validationSchema: newPasswordSchema,
})
const newPassword = defineComponentBinds('newPassword')
const newPasswordRepeat = defineComponentBinds('newPasswordRepeat')
const { isSubmitted } = useValidation(submitCount)

const authStore = useAuthStore()
const setNewPassword = handleSubmit(async (values) => {
  const route = useRoute()
  const token = route.query['reset-token'] as string
  if (token) {
    const isSuccess = await authStore.setNewPassword(token, values.newPassword)
    if (isSuccess) {
      emit('close')
      navigateTo('/')
    }
  } else {
    const { $notify } = useNuxtApp()
    $notify({
      text: 'Reset token not found',
      severity: 'error',
    })
  }
})

const isSubmitDisabled = computed(() => {
  return (
    isSubmitted.value &&
    (!!errors.value.newPassword || !!errors.value.newPasswordRepeat)
  )
})
</script>

<template>
  <form
    class="flex flex-col items-center"
    novalidate
    @submit.prevent="setNewPassword"
  >
    <KeyIcon aria-hidden="true" />
    <h2 class="mb-8 mt-6 text-4xl font-normal">Set New Password</h2>
    <div class="flex w-full flex-col items-start">
      <div class="p-float-label w-full">
        <Password
          id="new-password"
          v-bind="newPassword"
          :class="[
            'w-full',
            { 'p-invalid': errors.newPassword && isSubmitted },
          ]"
          input-class="w-full"
          :feedback="false"
          toggle-mask
        />
        <label for="new-password">New Password</label>
      </div>
      <small
        v-if="errors.newPassword && isSubmitted"
        class="p-error mt-1 text-left"
      >
        {{ errors.newPassword }}
      </small>
      <div class="p-float-label mt-7 w-full">
        <Password
          id="new-password-confirm"
          v-bind="newPasswordRepeat"
          :class="[
            'w-full',
            { 'p-invalid': errors.newPasswordRepeat && isSubmitted },
          ]"
          input-class="w-full"
          :feedback="false"
          toggle-mask
        />
        <label for="new-password-confirm">Repeat Password</label>
      </div>
      <small
        v-if="errors.newPasswordRepeat && isSubmitted"
        class="p-error mt-1 text-left"
      >
        {{ errors.newPasswordRepeat }}
      </small>
      <Button
        type="submit"
        class="mt-6 w-full justify-center [&>.p-button-label]:flex-none"
        :disabled="isSubmitDisabled || authStore.isProcessing"
        :label="!authStore.isProcessing ? 'Restore' : 'Loading'"
        :icon="authStore.isProcessing ? 'pi pi-spin pi-spinner' : undefined"
      />
    </div>
  </form>
</template>
